.header {
    height: 100%;
    border-bottom: 1px solid var(--primary-black);
    background-color: var(--primary-red);
}

.text {
    padding-left: 20px;
    user-select: none;
}

.img {
    max-width: 100%;
    max-height: 75px;
    border: 3px solid var(--primary-gray);
    border-radius: 3px;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.top {
    margin-top: 2%;
    height: 10%;
}

.middle {
    height: 80%;
}

.bottom {
    height: 10%;
}

.textField {
    width: 90%;
}

@media (min-width: 600px) {
    .textField {
        width: 70%;
    }
}

.radio {
    margin-left: 5px;
}

.datePicker {
    width: 90%;
    color: var(--primary-gray)!important;
    border-color: var(--primary-gray)!important;
}

@media (min-width: 600px) {
    .datePicker {
        width: 70%;
    }
}
.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    padding: 16px;
}

.closeBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.closeButton {
    align-self: flex-end;
}

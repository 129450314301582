.eMailTextField {
    width: 70%;
}


@media (min-width: 600px) {
    .eMailTextField {
        width: 30%;
    }
    
}
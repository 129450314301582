:root {
    --primary-red: #c6183e;
    --primary-black: #000000;
    --primary-gray: #f1efed;
}

.flexContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.headerRow {
    flex: 0 0 10%;
}

.contentRow {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 96%;
    margin-inline: 2%;
    /* background-color: yellow; */
}

@media (min-width: 600px) {
    .contentRow {
        width: 80%;
        margin-inline: 10%;
    }
}

.footerRow {
    flex: 0 0 8%;
}

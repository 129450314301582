.container {
    height: 100%;
}

.mapRow {
    height: 50vh;
}

.treeRow {
    background-color: var(--primary-gray);
}

.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    padding: 16px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modalTitle {
    font-size: 30px;
}

.modalText {
    font-size: 20px;
}

.checkboxLabel {
    display: flex;
    align-items: center;
}

.checkboxInput {
    margin-right: 8px;
}

.buttonsContainer {
    display: flex;
    align-items: center;
    gap: 0px;
    margin-top: 16px;
}

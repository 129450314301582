.tableHeaderCell {
    font-weight: bold;
    background-color: var(--primary-gray);
}

.tableRowEven {
    background-color: white;
}

.tableRowOdd {
    background-color: var(--primary-gray);
}

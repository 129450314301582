.noteRow {
    height: 200px;
}

/* .table {
    height: 100%;
} */

.note {
    width: 80%;
}

.noteTextArea {
    min-height: 150px;
}

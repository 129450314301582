.flexContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.stepperRow {
    margin-top: 20px;
    flex: 0 0 5%;
}

.pageRow {
    flex: 1;
}

.bottomRow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftBlock {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-block: 10px;
}

.rightBlock {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-block: 10px;
}

/* .loadingLabel {
    margin-right: 8px;
}

.loadingCircle {
    margin-right: 10px;
}

.backButton {
    margin-right: 5px;
}

.nextButton {
    margin-right: 10px;
} */

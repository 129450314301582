.footer {
    height: 100%;
    border-top: 1px solid var(--primary-black);
    background-color: var(--primary-red);
}

.row {
    align-items: center;
    justify-content: center;
}

.verticalBox {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.link {
    text-decoration: none;
}